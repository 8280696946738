<template>
  <v-container fluid>
    <section class="m-inner-section ma-4">
      <div class="m-inner-page">
        <v-row align="center" class="my-0">
          <v-col md="6">
            <h6 class="text-h6 gray--text text--darken-3">Sənədlər</h6>
          </v-col>
          <v-col md="6">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                depressed
                @click="addNewDocument()"
                v-if="page === 'edit'"
              >
                <v-icon class="mr-2" medium> mdi-plus </v-icon>
                <span> Əlavə et </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

      <List
        :totalCount="totalCount"
        :items="items"
        :isFetchingItems="isFetchingItems"
        @editItem="handleEditItem"
        @deleteItem="handleDeleteItem"
        :page="page"
        :employeeId="parseInt(employeeId)"
      />

      <DocumentForm
        :item="selectedDocument"
        v-model="dialog"
        @close="closeDialog"
        @save="handleSaveNewDocument"
        @update="handleEditDocument"
        v-if="page === 'edit'"
        :isLoading="loading"
      />

      <ConfirmationPopup
        id="delete-document-modal"
        title="Sənədin silinməsi"
        v-if="deletedDocument"
        :dialog="confirmationDialog"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
        :confirmText="'Sil'"
      >
        <span class="font-weight-bold">
          {{ deletedDocument.fileName }}
        </span>
        adlı sənədi silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </section>
  </v-container>
</template>

<script>
import List from "./components/List/index.vue";
import API from "@/services";
import DocumentForm from "./components/Form/Document.vue";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../../../../event-bus";

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      isFetchingItems: false,
      totalCount: 0,
      employeeId: null,
      selectedDocument: null,
      dialog: false,
      confirmationDialog: false,
      deletedDocument: null,
      loading: false,
    };
  },
  components: {
    List,
    DocumentForm,
    ConfirmationPopup,
  },
  methods: {
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.employeeId = this.$route.params.id;
        this.fetchHREmployeeDocuments(this.employeeId);
      }
    },
    fetchHREmployeeDocuments(id) {
      this.isFetchingItems = true;
      API.fetchHREmployeeDocuments(id)
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Sənədləri əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    insertHREmployeeDocument(id, data) {
      this.loading = true;
      API.insertHREmployeeDocument(id, data)
        .then(() => {
          this.closeDialog();
          this.fetchHREmployeeDocuments(id);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Sənəd əlavə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Sənəd əlavə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateHREmployeeDocument(id, documentId, data) {
      this.loading = true;
      API.updateHREmployeeDocument(id, documentId, data)
        .then(() => {
          this.closeDialog();
          this.fetchHREmployeeDocuments(id);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Sənəd redaktə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Sənəd redaktə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createFormData(form) {
      let formData = new FormData();
      formData.append("employeeDocumentTypeId", form.employeeDocumentTypeId);
      form.description
        ? formData.append("description", form.description)
        : null;
      form.document ? formData.append("document", form.document) : null;

      return formData;
    },
    addNewDocument() {
      this.selectedDocument = null;
      this.dialog = true;
    },
    handleEditItem(item) {
      this.selectedDocument = { ...item };
      this.dialog = true;
    },
    handleDeleteItem(item) {
      this.deletedDocument = { ...item };
      this.confirmationDialog = true;
    },
    closeDialog() {
      this.selectedDocument = null;
      this.dialog = false;
    },
    handleSaveNewDocument(form) {
      let formData = this.createFormData(form);
      this.insertHREmployeeDocument(this.employeeId, formData);
    },
    handleEditDocument(form) {
      let formData = this.createFormData(form);
      this.updateHREmployeeDocument(this.employeeId, form.id, formData);
    },
    handleDeleteConfirmation() {
      API.deleteHREmployeeDocument(this.employeeId, this.deletedDocument.id)
        .then(() => {
          this.fetchHREmployeeDocuments(this.employeeId);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Sənəd silindi.",
          });
          this.handleDeleteCancellation();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Sənədi silmək mümkün olmadı.",
          });
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.deletedDocument = null;
    },
  },
  created() {
    this.checkID();
  },
};
</script>
<style></style>
