<template>
  <Documents :page="'view'" />
</template>

<script>
import Documents from '../../../Shared/Pages/Documents/index.vue'

export default {
  components: {
    Documents
  }
}
</script>
